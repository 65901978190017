<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(saveUser)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>
						{{ modalTitle(name) }}
						<strong>Usuário</strong>
					</span>
					<span v-if="user.id">#{{ user.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="mb-3">
					<div class="columns">
						<div class="column">
							<InputWithValidation name="name" rules="required|min:3" type="text" label="Nome" size="is-medium" v-model="user.first_name" />
						</div>
						<div class="column">
							<InputWithValidation rules="required|min:3" type="text" label="Sobrenome" size="is-medium" v-model="user.last_name" />
						</div>
					</div>

					<InputWithValidation class="mb-4" rules="required|email" type="email" label="E-mail" size="is-medium" v-model="user.email" />

					<SelectWithValidation class="mb-4" rules="required" label="Perfil" size="is-medium" v-model="user.permission_id">
						<option v-for="r in permission" :value="r.id" :key="r.id">{{ r.name }}</option>
					</SelectWithValidation>

					<div v-if="user.permission_id && user.permission_id != 3">
						<b-field label="Dealers" class="mb-4">
							<span class="select-all" :class="titleAllDealers.toLowerCase()" @click="addAllDealers">{{ titleAllDealers }}</span>
							<multiselect v-model="user.dealers" name="delaers" :options="dealers" label="name" track-by="name" placeholder="" multiple preserve-search open-direction="top" :maxHeight="250" :close-on-select="false" :clear-on-select="false" selectLabel="Selecionar" selectedLabel="Selecionado" deselectLabel="Remover">
								<span slot="noResult">Nenhum dealer encontrado</span>
							</multiselect>
						</b-field>
					</div>
					<div v-if="user.permission_id == 2">
						<b-field label="Critérios" class="mb-4">
							<span class="select-all" :class="titleAllCriterias.toLowerCase()" @click="addAllCriterias">{{ titleAllCriterias }}</span>
							<multiselect v-model="user.criterias" name="delaers" :options="criterias" label="name" track-by="name" placeholder="" multiple preserve-search open-direction="top" :maxHeight="250" :close-on-select="false" :clear-on-select="false" selectLabel="Selecionar" selectedLabel="Selecionado" deselectLabel="Remover">
								<span slot="noResult">Nenhum critério encontrado</span>
							</multiselect>
						</b-field>
					</div>
					<InputWithValidation rules="required|min:8" type="password" label="Senha" size="is-medium" password-reveal v-model="user.password" v-if="name === 'New'" />
					<InputWithValidation type="password" label="Senha" size="is-medium" password-reveal v-model="user.password" v-if="name === 'Edit'" :disabled="root === 'root'" />
					<password-meter class="mb-2" :password="user.password" />

					<small class="modal-updated" v-if="user.updated_at">Última Modificação: {{ format(user.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Fechar</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">Salvar</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import { ValidationObserver } from 'vee-validate'
import PasswordMeter from 'vue-simple-password-meter'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'

export default {
	components: {
		Multiselect,
		InputWithValidation,
		SelectWithValidation,
		ValidationObserver,
		PasswordMeter
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			user: {},
			role: 1,
			permission: [],
			visible: false,
			dealers: [],
			criterias: []
		}
	},
	methods: {
		async getAllRoles() {
			try {
				const response = await Api.get('permission/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.permission = data
				}
			} catch (e) {
				console.log(e)
			}
		},
		async getAllDealers() {
			try {
				const response = await Api.get('dealer/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.dealers = data
					this.$refs.form[0].focus()
				}
			} catch (e) {
				console.log(e)
			}
		},
		async getAllCriterias() {
			try {
				const response = await Api.get('criteria/findAll')

				if (response.status === 200) {
					this.criterias = response.data
				}
			} catch (e) {
				console.log(e)
			}
		},
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`user/update/${this.id}`, this.user)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/users')
					successToast('O usuário foi <strong>atualizado</strong> com sucesso!')
					eventHub.$emit('reload-users')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					let message = 'Ocorreu um <strong>erro</strong> ao atualizar o usuário!'

					if (e.data && e.data.description) {
						message = e.data.description
					}

					errorToast(message)
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`user/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.user = data
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post('user/store', this.user)
				const { status } = response
				if (status === 201 || status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/users')
					successToast('O usuário foi <strong>criado</strong> com sucesso!')
					eventHub.$emit('reload-users')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast('Ocorreu um <strong>erro</strong> ao criar o usuário! <small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}

			this.loading = true
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		async saveUser() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		addAllDealers() {
			if (this.user.dealers && this.user.dealers.length == this.dealers.length) {
				this.$set(this.user, 'dealers', [])
			} else {
				this.$set(this.user, 'dealers', this.dealers)
			}
		},
		addAllCriterias() {
			if (this.user.criterias && this.user.criterias.length == this.criterias.length) {
				this.$set(this.user, 'criterias', [])
			} else {
				this.$set(this.user, 'criterias', this.criterias)
			}
		}
	},
	mounted() {
		this.findById()
		this.getAllRoles()
		this.getAllDealers()
		this.getAllCriterias()
	},
	computed: {
		titleAllDealers() {
			let title = 'Adicionar Todos'

			if (this.user.dealers && this.user.dealers.length == this.dealers.length) {
				title = 'Remover Todos'
			}

			return title
		},
		titleAllCriterias() {
			let title = 'Adicionar Todos'

			if (this.user.criterias && this.user.criterias.length == this.criterias.length) {
				title = 'Remover Todos'
			}

			return title
		}
	}
}
</script>
